@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'page.scss';
@import 'components.scss';
@import 'variable.scss';
@import 'font.scss';
@import 'react-toastify/dist/ReactToastify.css';

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  //   'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // prevent copy
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.app {
  font-family: Roboto;
  font-size: var(--text-main);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button:hover {
  box-shadow:
    var(--color-three) 0px 0px 10px,
    var(--color-three) 0px 0px 1px;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.header {
  display: flex;
  height: 10rem;
}

.header-item {
  margin-right: 3rem;
  cursor: pointer;
  padding: 2rem;
  font-weight: 700;
}
