:root {
  --color-main: #1a1d23;
  --color-second: #ffffff;
  --color-three: #007a00;
  --color-four: #EAB308;
  --color-stroke: #edf2f5;
  --color-background-content: #ffffff;

  // --text-logo: 100px;
  // --text-title: 50px;
  // --text-heading: '24px';
  --text-main: 16px;
}
